import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GetUserTypeResponse } from './account-user-type'
import { HttpClientOptionsService } from '../../../authentication/services/http-client-options.service'
import { environment } from '../../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class AccountUsersService {
    constructor(
        private httpClient: HttpClient,
        private httpOptionsService: HttpClientOptionsService,
    ) {}

    updateAccountUserRole(accountUserToUpdate: any) {
        return this.httpClient.put<any>(
            `${environment.URL_BASE}/partnerhub/account-users/update-account-user-type`,
            {},
            this.httpOptionsService.getPartnerhubAuthorizationAndParams(accountUserToUpdate),
        )
    }

    getAccountUsers(filterAttributes: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/get-account-users`,
            filterAttributes,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    getInternalUsersList(role: string) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/account-users/query-internal-users/${role}`,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    getUserTypes() {
        const filterAtributes = {
            registers_per_page: 100,
            page_number: 1,
        }
        const url = `${environment.URL_BASE}/partnerhub/account-user-roles/get-user-types`
        const options = this.httpOptionsService.getPartnerhubAuthorizationAndParams(filterAtributes)
        return this.httpClient.get<GetUserTypeResponse>(url, options)
    }

    deactivateAccountUser(accountUserIdToDeactivate: any) {
        return this.httpClient.delete<any>(
            `${environment.URL_BASE}/partnerhub/account-users/deactivate-account-user/${accountUserIdToDeactivate}`,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    //@ts-ignore
    createAccountUser(accountUserToCreate: any) {
        if (accountUserToCreate.user_role == 'user-admin-app')
            return this.createPartnerhubAdministratorAccountUserPreRegister(accountUserToCreate)
        if (accountUserToCreate.user_role == 'user-bizdev')
            return this.createPartnerHubBusinessDeveloperAccountUserPreRegister(accountUserToCreate)
        if (accountUserToCreate.user_role == 'user-admin-partner')
            return this.createPartnerHubPartnerAdministratorAccountUserPreRegister(accountUserToCreate)
        if (accountUserToCreate.user_role == 'user-sales-person')
            return this.createPartnerHubSalesPersonAccountUserPreRegister(accountUserToCreate)
    }

    createAccountUserPreRegister(accountUserPreRegisterToCreate: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/create-account-user-pre-register`,
            accountUserPreRegisterToCreate,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    createPartnerhubAdministratorAccountUserPreRegister(accountUserToCreate: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/create-pre-register-account-user-administrator`,
            accountUserToCreate,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    createPartnerHubBusinessDeveloperAccountUserPreRegister(accountUserToCreate: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/create-pre-register-account-user-business-developer`,
            accountUserToCreate,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    createPartnerHubPartnerAdministratorAccountUserPreRegister(accountUserToCreate: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/create-pre-register-account-user-partner-administrator`,
            accountUserToCreate,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    createPartnerHubSalesPersonAccountUserPreRegister(accountUserToCreate: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/create-pre-register-account-user-sales-person`,
            accountUserToCreate,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    sendPreRegisterInvitation(accountUserIdToSendPreRegisterInvitation: any) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/account-users/send-account-user-pre-register-invitation/${accountUserIdToSendPreRegisterInvitation}`,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    bindBusinessDeveloperToAccountUser(accountUserIdAndBusinessDeveloperIdToBind: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/bind-business-developer-to-account-user`,
            accountUserIdAndBusinessDeveloperIdToBind,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    removeBoundOfBusinessDeveloperAndAccountUser(accountUserId: any) {
        return this.httpClient.delete<any>(
            `${environment.URL_BASE}/partnerhub/account-users/remove-bound-of-business-developer-and-account-user/${accountUserId}`,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    getAllActivesBusinessDevelopers() {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/business-developers/get-all-active-business-developers`,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    getAccountUserHistory(accountUserId: any) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/account-users/get-account-user-history/${accountUserId}`,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }

    updateUserStatus(userObj: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/account-users/update-user-status`,
            userObj,
            this.httpOptionsService.getPartnerhubAuthorization(),
        )
    }
}
